<template>
  <div class="tw-flex tw-flex-col tw-gap-y-4">
    <div v-if="property.criteria_list && property.criteria_list.length">
      <slot name="section-title" :title="mainListTitle">
        <h3 v-if="!isOldStructure && mainListTitle">{{ mainListTitle }}</h3>
        <library-title
          v-else-if="isOldStructure && mainListTitle"
          :text="mainListTitle"
          class="tw-my-5 tw-font-black"
          tag="h3"
        >
        </library-title>
      </slot>
      <div class="tw-grid tw-grid-cols-12 tw-gap-4 tw-pt-4">
        <div
          v-for="(criteria, index) in property?.criteria_list"
          :key="`criteria-${index}`"
          class="tw-col-span-12 md:tw-col-span-6 2xl:tw-col-span-4"
        >
          <slot name="list-item" :criteria="criteria"></slot>
        </div>
      </div>
    </div>
    <div v-if="property.is_rent && property?.lettings_criteria_list?.length">
      <slot name="section-title" :title="lettingListTitle">
        <h3 v-if="!isOldStructure">{{ lettingListTitle }}</h3>
        <library-title
          v-else-if="isOldStructure && lettingListTitle"
          :text="lettingListTitle"
          class="tw-my-5 tw-font-black"
          tag="h3"
        >
        </library-title>
      </slot>
      <div class="tw-grid tw-grid-cols-12 tw-gap-4 tw-pt-4">
        <div
          v-for="(criteria, index) in property?.lettings_criteria_list"
          :key="`criteria-${index}`"
          class="tw-col-span-12 md:tw-col-span-6 2xl:tw-col-span-4"
        >
          <slot name="list-item" :criteria="criteria"></slot>
        </div>
      </div>
    </div>
    <div v-if="property?.flood_list?.length">
      <slot name="section-title" :title="floodListTitle">
        <h3 v-if="!isOldStructure">{{ floodListTitle }}</h3>
        <library-title
          v-else-if="isOldStructure && floodListTitle"
          :text="floodListTitle"
          class="tw-my-5 tw-font-black"
          tag="h3"
        ></library-title>
      </slot>

      <div class="tw-grid tw-grid-cols-12 tw-gap-4 tw-pt-4">
        <div
          v-for="(criteria, index) in property?.flood_list"
          :key="`criteria-${index}`"
          class="tw-col-span-12 md:tw-col-span-6 2xl:tw-col-span-4"
        >
          <slot name="list-item" :criteria="criteria"></slot>
        </div>
      </div>
    </div>
    <div v-if="property?.additional_information_list?.length">
      <slot name="section-title" :title="additionalListTitle">
        <h3 v-if="!isOldStructure">{{ additionalListTitle }}</h3>
        <library-title
          v-else-if="isOldStructure && additionalListTitle"
          :text="additionalListTitle"
          class="tw-my-5 tw-font-black"
          tag="h3"
        ></library-title>
      </slot>
      <div class="tw-grid tw-grid-cols-12 tw-gap-4 tw-pt-4">
        <div
          v-for="(criteria, index) in property?.additional_information_list"
          :key="`criteria-${index}`"
          class="tw-col-span-12 md:tw-col-span-6 2xl:tw-col-span-4"
        >
          <slot name="list-item" :criteria="criteria"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import type { PropertyItemComponent } from '~/units/properties/types';
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'LibraryPropertiesCriteriaList',

  props: {
    property: {
      required: true,
      type: Object as PropType<PropertyItemComponent>,
    },

    mainListTitle: {
      required: false,
      type: String,
      default: 'Property Information',
    },

    lettingListTitle: {
      required: false,
      type: String,
      default: 'Letting Details',
    },

    floodListTitle: {
      required: false,
      type: String,
      default: 'Flood',
    },

    additionalListTitle: {
      required: false,
      type: String,
      default: 'Additional Information',
    },
  },

  computed: {
    ...mapState(useWebsiteStore, ['isOldStructure']),
  },
});
</script>
